import { Steps } from '../components/CreateSample/utils/StepperSteps'; // -- Function for tracking clicks on links

export function tealium_link(event_attr1, event_attr2) {
  if (true) {
    const windowRef = window;

    if (typeof windowRef.utag !== 'undefined') {
      windowRef.utag.link({
        event_attr1,
        event_attr2
      });
    }
  }
}
export const KPITealiumTags = {
  ANALYZES: 'clickanalyzes::module',
  MACHINES_URGENT: 'clickmachinesurgent::module',
  PARTS_IN_ALERT: 'clickpartinalert::module',
  REPORTS: 'clickreports::module',
  REPORTS_TO_VALIDATE: 'clickreportstovalidate::module',
  REPORTS_WAITING_FOR_FEEDBACK: 'clickawaitingfeedbacks::module',
  SAMPLES_IN_PROGRESS: 'clickinprogresssample::module'
};
export const StepsTealiumTags = {
  [Steps.MACHINE]: 'creationmachine::bouttonvalidation',
  [Steps.PART]: 'creationorgane::bouttonvalidation',
  [Steps.SAMPLE]: 'creationechantillon::bouttonvalidation',
  [Steps.KITS]: 'creationkit::bouttonvalidation',
  [Steps.ADDRESS]: 'creationadresse::bouttonvalidation',
  [Steps.RECAP]: null
};