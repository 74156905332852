import _defineProperty from "/app/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/defineProperty.js";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

import { ApolloClient, createHttpLink, from, InMemoryCache } from '@apollo/client';
import { flatten, map, join } from 'lodash';
import errorsLink from './errors';
let apolloClient;

const getOperationNamesQueryString = body => {
  const operations = flatten([JSON.parse(body)]);
  const operationNames = map(operations, operation => `operationName=${operation.operationName}`);
  return join(operationNames, '&');
};

const createApolloClient = () => {
  const httpLink = createHttpLink({
    uri: `/api/graphql`,
    fetch: (uri, options) => {
      return fetch(`${uri}?${getOperationNamesQueryString(options === null || options === void 0 ? void 0 : options.body)}`, options);
    }
  }); // if some request must not be batched, see https://github.com/apollographql/apollo-link/issues/809 to add exceptions
  // const batchLink = new BatchHttpLink({
  //   uri: `/api/graphql`,
  //   // batchMax: 5, // No more than 5 operations per batch
  //   // batchInterval: 20, // Wait no more than 20ms after first batched operation
  //   fetch: (uri, options) => {
  //     return fetch(
  //       `${uri}?${getOperationNamesQueryString(options?.body as string)}`,
  //       options
  //     );
  //   },
  // });

  return new ApolloClient({
    ssrMode: false,
    link: from([errorsLink, httpLink]),
    // link: from([errorsLink, batchLink]),
    cache: new InMemoryCache()
  });
};

export const initializeApollo = initialState => {
  // eslint-disable-next-line no-underscore-dangle
  const _apolloClient = apolloClient ?? createApolloClient(); // If your page has Next.js data fetching methods that use Apollo Client, the initial state
  // gets hydrated here


  if (initialState) {
    // Get existing cache, loaded during client side data fetching
    const existingCache = _apolloClient.extract(); // Restore the cache using the data passed from getStaticProps/getServerSideProps
    // combined with the existing cached data


    _apolloClient.cache.restore(_objectSpread(_objectSpread({}, existingCache), initialState));
  } // For SSG and SSR always create a new Apollo Client


  if (false) return _apolloClient; // Create the Apollo Client once in the client

  if (!apolloClient) apolloClient = _apolloClient;
  return _apolloClient;
};
export function useApollo(initialState) {
  return initializeApollo(initialState);
}