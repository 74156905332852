import createSpacing from '@material-ui/core/styles/createSpacing';
import palette from './palette';
import typography from './typography';
import overrides from './overrides';
import shadows from './shadows';
import { createTheme } from '@material-ui/core/styles';

const theme = createTheme({
  name: 'Total',
  palette,
  typography,
  overrides,
  shadows,
  spacing: createSpacing(8), // Spacing value in maquette
});

export default theme;
