import { useEffect } from 'react';
import { useMeQuery } from '../../graphql/schema';
import getConfig from 'next/config';
import { useSession } from 'next-auth/react';

const getLocaleTaggingCode = languageId => {
  if (languageId === 'en') {
    return '2';
  } else if (languageId === 'fr') {
    return '1';
  } else if (languageId === 'de') {
    return '3';
  } else if (languageId === 'ru') {
    return '22';
  } else if (languageId === 'zh') {
    return '8';
  } else if (languageId === 'pt') {
    return '6';
  } else if (languageId === 'nl') {
    return '4';
  } else if (languageId === 'es') {
    return '5';
  } else if (languageId === 'it') {
    return '0';
  } else if (languageId === 'tr') {
    return '25';
  } else if (languageId === 'cs') {
    return '11';
  } else if (languageId === 'hu') {
    return '14';
  } else if (languageId === 'ko') {
    return '0';
  } else {
    return '0';
  }
};

const getPageSlugFromPath = (path, query) => {
  if (path.includes('/machine/[id]')) {
    return 'machine-details';
  }

  if (path.includes('/reports-to-validate/[id]')) {
    return 'reports-to-validate-details';
  }

  if (path.includes('/reports/[id]')) {
    return 'reports-details';
  }

  if (path.includes('/unit')) {
    return 'unit';
  }

  if (path.includes('/user/my-account')) {
    return 'my-account';
  }

  if (path.includes('/404')) {
    return '404';
  }

  if (path.includes('/_app')) {
    return '_app';
  }

  if (path.includes('/_document')) {
    return '_document';
  }

  if (path.includes('/anac-be-onboarding')) {
    return 'anac-be-onboarding';
  }

  if (path.includes('/create-sample')) {
    const queryKeys = Object.keys(query);

    if (queryKeys.includes('machlink')) {
      if (queryKeys.includes('partlink')) {
        return 'create-sample';
      }

      return 'create-part';
    }

    return 'create-machine';
  }

  if (path.includes('/dashboard')) {
    return 'dashboard';
  }

  if (path.includes('/dashboardMessage')) {
    return 'dashboardMessage';
  }

  if (path.includes('/edit-part')) {
    return 'edit-part';
  }

  if (path.includes('/reports-to-validate')) {
    return 'reports-to-validate';
  }

  if (path.includes('/reports')) {
    return 'reports';
  }

  if (path.includes('/samples/newSamples')) {
    return 'newSamples';
  }

  if (path.includes('/samples/prSamples')) {
    return 'prSamples';
  }

  if (path.includes('/statistics/invoices')) {
    return 'invoices';
  }

  if (path.includes('/statistics/managementReport')) {
    return 'managementReport';
  }

  if (path.includes('/statistics/statistics')) {
    return 'statistics';
  }

  if (path.includes('/support')) {
    return 'support';
  }

  if (path.includes('/unauthorized')) {
    return 'unauthorized';
  }

  if (path.includes('/users')) {
    return 'users';
  }

  if (path === '/') {
    return 'login';
  }

  return 'unhandled-path';
};

const TealiumProvider = _ref => {
  var _meData$me;

  let {
    children,
    locale,
    events,
    path,
    query
  } = _ref;
  const unauthPaths = ['/', '/anac-be-onboarding'];
  const {
    data: session,
    status
  } = useSession();
  const loading = status === 'loading';
  const {
    data: meData
  } = useMeQuery({
    skip: unauthPaths.includes(path) || loading || !session // Login page

  });
  const user_profile = meData === null || meData === void 0 ? void 0 : (_meData$me = meData.me) === null || _meData$me === void 0 ? void 0 : _meData$me.profile;
  const {
    publicRuntimeConfig
  } = getConfig();
  const {
    TEALIUM_ENV,
    TEALIUM_ACCOUNT
  } = publicRuntimeConfig;
  const windowRef = true ? window : null;

  const onRouteChangeComplete = (pathname, query) => {
    if (TEALIUM_ENV && TEALIUM_ENV !== 'local' && meData !== null && meData !== void 0 && meData.me && windowRef) {
      windowRef.utag_data = {
        event: 'tealium-pv',
        page_name: getPageSlugFromPath(pathname, query),
        site_name: TEALIUM_ACCOUNT,
        env_type: TEALIUM_ENV,
        language: getLocaleTaggingCode(locale),
        user_profile
      };

      if (windowRef.utag) {
        windowRef.utag.view(windowRef.utag_data);
      }
    }
  }; // Second useEffect to handle initial page view after tealium is loaded


  useEffect(() => {
    onRouteChangeComplete(path, query);
  }, [windowRef === null || windowRef === void 0 ? void 0 : windowRef.utag]);
  useEffect(() => {
    // Record a pageview when route changes and Unassign event listener
    events.on('routeChangeComplete', onRouteChangeComplete);
    return () => {
      events.off('routeChangeComplete', onRouteChangeComplete);
    };
  }, [locale, events, user_profile]);
  return children;
};

export default TealiumProvider;