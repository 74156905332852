import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import ModalMUI from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Typography from '@material-ui/core/Typography';
import Fade from '@material-ui/core/Fade';
import CloseIcon from '@material-ui/icons/Close';
import PropTypes from 'prop-types';
import IconButton from '@material-ui/core/IconButton';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import { black, grey02 } from '../../theme/colors';

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    overflowY: 'scroll',
  },
  container: {
    padding: '30px',
    margin: 'auto',
    outlineStyle: 'none',
    zIndex: 0, // fixes scroll in Firefox
    width: 1150,
    maxWidth: '100%',
  },
  paper: {
    // backgroundColor: theme.palette.background.paper,
    // border: '2px solid #000',
    // boxShadow: theme.shadows[5],
    // padding: '30px',
    // maxWidth: '940px',
  },
  modalBloc: {
    paddingLeft: 52,
    paddingRight: 52,
    paddingTop: 35,
    paddingBottom: 35,
  },
  close: {
    // border: 'solid 0.5px #979797',
    // borderRadius: 1000,
    // color: grey02,
    // float: 'right',
    // '&:hover': {
    //   cursor: 'pointer',
    // },
    // marginRight: '-10px',
    // marginTop: '-10px',
  },
  title: {
    // color: '#9b9b9b',
    // marginBottom: 30,
  },
}));

const Modal = ({
  title,
  subTitle,
  open,
  onClose,
  children,
  modalProps,
  hideCloseIcon,
  paperStyle,
  containerStyle,
}) => {
  const classes = useStyles();

  const close = (e) => {
    e.stopPropagation();
    onClose();
  };
  return (
    <>
      <ModalMUI
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        onClose={close}
        disableAutoFocus
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
        onClick={(e) => {
          e.stopPropagation(); // prevent click on background elements
        }}
        {...modalProps}>
        <Fade in={open}>
          <div className={classes.container} style={containerStyle}>
            <Paper className={classes.paper} style={paperStyle}>
              <div
                style={{
                  paddingLeft: 63,
                  paddingRight: 32,
                  paddingTop: 42,
                  paddingBottom: 40,
                  borderBottom: '1px #e5e5e5 solid',
                }}>
                <Grid container spacing={3}>
                  <Grid style={{ flexGrow: 1 }}>
                    {title && typeof title === 'string' ? (
                      <Typography
                        data-testid="modal.title"
                        variant="h2"
                        className={classes.title}>
                        {title}
                      </Typography>
                    ) : (
                      title
                    )}
                  </Grid>
                  {!hideCloseIcon && (
                    <Grid>
                      <IconButton
                        size="small"
                        style={{
                          padding: 3,
                          border: 'solid 0.5px #979797',
                          borderRadius: 1000,
                          color: grey02,
                        }}>
                        <CloseIcon
                          onClick={close}
                          data-testid="closeButton"
                          className={classes.close}
                          fontSize="large"
                          style={{ fontSize: 25 }}
                        />
                      </IconButton>
                    </Grid>
                  )}
                </Grid>
              </div>
              {subTitle && <Typography variant="body">{subTitle}</Typography>}
              <div className={classes.modalBloc}>{children}</div>
            </Paper>
          </div>
        </Fade>
      </ModalMUI>
    </>
  );
};

Modal.propTypes = {
  title: PropTypes.any,
  subTitle: PropTypes.any,
  // There no specific form for this object, it will be determined by the child.
  // eslint-disable-next-line react/forbid-prop-types
  onClose: PropTypes.func,
  open: PropTypes.bool,
  hideCloseIcon: PropTypes.bool,
  modalProps: PropTypes.shape(ModalMUI.propTypes),
  paperStyle: PropTypes.shape({}),
  containerStyle: PropTypes.shape({}),
};

Modal.defaultProps = {
  title: undefined,
  subTitle: undefined,
  onClose: undefined,
  open: undefined,
  hideCloseIcon: false,
  modalProps: {},
  paperStyle: {},
  containerStyle: {},
};

export default Modal;
