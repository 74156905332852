import PropTypes from 'prop-types';

export const headerPropTypes = {
  logoPath: PropTypes.string,
  logoComponent: PropTypes.node,
  tab: PropTypes.string,
  tabs: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string.isRequired,
      iconClass: PropTypes.elementType,
      href: PropTypes.string,
      label: PropTypes.string,
      subtabs: PropTypes.arrayOf(
        PropTypes.shape({
          label: PropTypes.string.isRequired,
          href: PropTypes.string,
          func: PropTypes.func,
        })
      ),
    })
  ),
  accountName: PropTypes.oneOfType([PropTypes.element, PropTypes.string])
    .isRequired,
  isLoginPage: PropTypes.bool.isRequired,
  locale: PropTypes.string.isRequired,
  locales: PropTypes.arrayOf(PropTypes.string).isRequired,
  localesTranslations: PropTypes.shape({}).isRequired,
  accountMenuItems: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      href: PropTypes.string,
      func: PropTypes.func,
    })
  ),
  isMobile: PropTypes.bool,
  accountMenuLabel: PropTypes.string,
  logoutLabel: PropTypes.string,
  onLogout: PropTypes.func,
  onLangChange: PropTypes.func,
  TopBarRenderer: PropTypes.any,
  extraComponents: PropTypes.arrayOf(PropTypes.node),
};

export const headerDefaultProps = {
  logoPath: undefined,
  logoComponent: undefined,
  tab: undefined,
  tabs: undefined,
  accountName: undefined,
  accountMenuItems: [],
  isMobile: false,
  accountMenuLabel: undefined,
  logoutLabel: undefined,
  onLogout: undefined,
  onLangChange: () => {},
  TopBarRenderer: ({ children }) => children,
};
