import { uniq } from 'lodash';
import { ENV_AUTH_PROVIDERS_ANAC, ENV_AUTH_PROVIDERS_DIAGOTRUCKS } from '@/common/utils/env';
export let AppBrandings;

(function (AppBrandings) {
  AppBrandings["ANAC"] = "ANAC";
  AppBrandings["DIAGOTRUCKS"] = "DIAGOTRUCKS";
})(AppBrandings || (AppBrandings = {}));

export const getAppBranding = function () {
  let reqHost = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : null;
  const isSsr = false;
  const host = reqHost || (isSsr ? null : location.host);
  if (!host) return null;

  if (host.includes('diagotrucks')) {
    return AppBrandings.DIAGOTRUCKS;
  } else if (host.includes('anac')) {
    return AppBrandings.ANAC;
  } else if (host.includes('localhost')) {
    // for dev, you can switch appBranding here
    return AppBrandings.ANAC; // return AppBrandings.DIAGOTRUCKS;
  }

  return null;
};
export const getAuthProvidersIds = appBranding => {
  let providerIds = [];

  if (appBranding === AppBrandings.ANAC) {
    providerIds = [...ENV_AUTH_PROVIDERS_ANAC]; // in production : [digitalpass, gigya]
  }

  if (appBranding === AppBrandings.DIAGOTRUCKS) {
    providerIds = [...ENV_AUTH_PROVIDERS_DIAGOTRUCKS]; // in production : [credentials]
  }

  return uniq(providerIds);
};