import React from 'react';
import { headerDefaultProps, headerPropTypes } from './HeaderTypes';

import DesktopHeader from './DesktopHeader';
import MobileHeader from './MobileHeader';

const Header = (props) => {
  const { isMobile } = props;
  return isMobile ? <MobileHeader {...props} /> : <DesktopHeader {...props} />;
};

Header.propTypes = headerPropTypes;

Header.defaultProps = headerDefaultProps;

export default Header;
