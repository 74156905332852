export let Steps;

(function (Steps) {
  Steps["MACHINE"] = "MACHINE";
  Steps["PART"] = "PART";
  Steps["SAMPLE"] = "SAMPLE";
  Steps["RECAP"] = "RECAP";
  Steps["MACHINE_SEARCH"] = "MACHINE_SEARCH";
  Steps["PART_SELECTION"] = "PART_SELECTION";
  Steps["ADDRESS"] = "ADDRESS";
  Steps["KITS"] = "KITS";
})(Steps || (Steps = {}));

export const MachineFormSteps = [Steps.MACHINE, Steps.PART, Steps.SAMPLE, Steps.RECAP];
export const PartFormSteps = [Steps.PART, Steps.SAMPLE, Steps.RECAP];
export const SampleFormSteps = [Steps.SAMPLE, Steps.RECAP];
export const EditPartSteps = [Steps.MACHINE, Steps.PART];
export const EditSampleSteps = [Steps.SAMPLE];
export const MachineSearchSteps = [Steps.MACHINE_SEARCH, Steps.PART_SELECTION, Steps.SAMPLE, Steps.RECAP];
export const KitFormSteps = [Steps.KITS, Steps.ADDRESS, Steps.RECAP];