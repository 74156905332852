export const redTotal = '#ed0000';
export const carminRed = '#CC0000';
export const functionalRedFailure = '#E74C3C';
export const functionalOrangeWarning = '#F59600';
export const functionalGreenSuccess = '#278903';
export const functionalBlueInformation = '#2F7BB4';
export const black = '#374649';
export const grey01 = '#5F6B6D';
export const grey02 = '#7D8788';
export const grey03 = '#D7DADB';
export const grey04 = '#F9F9F9';
export const error01 = '#E74C3C';
export const white = '#FFFFFF';

export const grey6 = '#C2C2C2';
export const redTotalAlpha = '#FEEBEB'; // 'rgba(237, 0, 0, 0.08)';
export const redTotalAlphaChip = '#F24C4C'; // 'rgba(237, 0, 0, 0.7)';
export const black8 = '#EFF0F1'; // 'rgba(55, 70, 73, 0.08)';
export const grey05 = '#e4e4e4';
export const grey10 = '#F7F9FA';
export const tableRowGrey = '#374649';
export const grey07 = '#e9e9e9';
export const lightGrey = '#F4F4F4';

// graph / status / alert colors
export const greenData = '#DFEED9';
export const orangeData = '#FCE7C4';
export const redData = '#E74C3C';
export const blueData = '#CDE6F6';

// Nouvelles couleurs de la charte graphique
export const darkBlueTotal = '#285AFF';
export const lightBlueTotal = '#009BFF';
export const orangeTotal = '#FFC800';
export const blueGreenTotal = '#32C8C8';
export const greenTotal = '#28C896';
export const yellowGreenTotal = '#96E600';
export const greyTotal = '#7098A7';

// Gradients
export const gradientStyle = (color1, color2) => ({
  backgroundImage: `linear-gradient(to right, ${color1}, ${color2})`,
});

export const gradientTextStyle = (gradientStyle) => ({
  width: 'fit-content',
  backgroundClip: 'text',
  WebkitBackgroundClip: 'text',
  WebkitTextFillColor: 'transparent',
  ...gradientStyle,
});

export const gradientRed = gradientStyle('#FF6E00', '#FF0000');
export const gradientBlue = gradientStyle('#00AAFF', '#4632FF');
export const gradientLightBlue = gradientStyle('#00C8FF', '#0082FF');
export const gradientLightBlueReverse = gradientStyle('#0082FF', '#00C8FF');
export const gradientBlueGreen = gradientStyle('#00C8AA', '#00AAFF');
export const gradientGreen = gradientStyle('#78D700', '#00C8AF');
export const gradientGreenYellow = gradientStyle('#FFD700', '#3CD700');
export const gradientYellow = gradientStyle('#FFAF00', '#FFDC00');

export const reportGrey = '#35494C';
export const reportGreen = '#93C121';
export const reportOrange = '#F29205';
export const reportUrgentRed = '#E40523';
export const reportRunningIn = '#0A81ED';
export const reportRed = '#E40523';
