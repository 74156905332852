import React from 'react';
import { Typography, Link, Box } from '@material-ui/core';
import Copyright from '@material-ui/icons/Copyright';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { map } from 'lodash';
import { grey03 } from '../../theme/colors';

const LAYOUT_MAX_WIDTH = 1280;

const useStyles = ({ height, isMobile }) =>
  makeStyles((theme) => ({
    root: {
      width: '100%',
      height,
      backgroundColor: 'white',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      bottom: 0,
    },
    innerContainer: {
      maxWidth: LAYOUT_MAX_WIDTH,
      width: '100%',
      height: '100%',
      paddingLeft: 20,
      paddingRight: 20,
      display: 'flex',
      alignItems: 'center',
      flexDirection: isMobile ? 'column' : 'row',
      gap: isMobile ? '8px' : 'auto',
    },
    text: {
      height: 18,
      fontFamily: 'Arial',
      fontSize: 12,
      fontWeight: 'bold',
      color: grey03,
      borderColor: grey03,
      paddingRight: 5,
    },
    icon: {
      marginRight: 3,
      marginTop: isMobile ? -4 : 0,
      color: grey03,
      width: isMobile ? 16 : 24,
      height: isMobile ? 16 : 24,
    },
    logo: {
      paddingLeft: 10,
      height: isMobile ? 14 : 20,
    },
  }))();

const Footer = ({ height, links, logoPath, copyright, isMobile }) => {
  const classes = useStyles({ height, isMobile });
  return (
    <Box data-testid="footer" className={classes.root}>
      <div className={classes.innerContainer}>
        {map(links, ({ label, href, target }) => (
          <Typography className={classes.text} key={label}>
            <Link
              data-testid={label}
              href={href}
              className={classes.text}
              target={target}
            >
              {label}
            </Link>
          </Typography>
        ))}
        <div style={{ flexGrow: 1 }} />
        <Copyright className={classes.icon} />
        <Typography data-testid="copyright" className={classes.text}>
          {copyright}
        </Typography>
        {logoPath && <img src={logoPath} className={classes.logo} alt="Logo" />}
      </div>
    </Box>
  );
};

Footer.propTypes = {
  logoPath: PropTypes.string,
  copyright: PropTypes.string.isRequired,
  links: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      href: PropTypes.string.isRequired,
      target: PropTypes.string,
    })
  ),
  height: PropTypes.number,
  isMobile: PropTypes.bool,
};

Footer.defaultProps = {
  logoPath: undefined,
  links: [],
  height: 60,
  isMobile: PropTypes.false,
};

export default Footer;
