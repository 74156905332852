import { i18n } from '@lingui/core'; // The default locale of the site

export const defaultLocale = 'en'; // All the available locales for the site, must include the default one.

export const localesTranslations = {
  en: 'English',
  // Anglais
  fr: 'Français',
  //Francais
  de: 'Deutsch',
  // Allemand
  ru: 'Русский',
  // Russe
  zh: '简体中文',
  // Chinois,
  pt: 'Português',
  // Brésilien (Portugais-BR), see https://www.npmjs.com/package/make-plural, pt is brazil, pt_PT is portugal
  nl: 'Nederlands',
  // Néerlandais
  es: 'Español',
  // Espagnol
  it: 'Italiano',
  // Italien
  tr: 'Türkçe',
  // Turc
  cs: 'Čeština',
  // Tchèque
  hu: 'Magyar',
  // Hongrois
  ko: '한국어' // Coréen

};
/**
 * We do a dynamic import of just the catalog that we need
 * @param locale any locale string
 */

export async function dynamicActivate(locale) {
  try {
    const {
      messages
    } = await import(`../../../locales/${locale}/messages.po`);
    i18n.load(locale, messages);
    i18n.activate(locale);
  } catch (e) {
    console.error(e);
  }
}