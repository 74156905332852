import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Button,
  Typography,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Radio,
  MenuItem,
  Popper,
  Paper,
  MenuList,
} from '@material-ui/core';
import { map } from 'lodash';
import Language from '@material-ui/icons/Language';
import Modal from '../Modal';

const useStylesDesktop = makeStyles((theme) => ({
  langArea: {
    marginTop: 5,
  },
  cursor: {
    '&:hover': {
      cursor: 'pointer',
      color: theme.palette.primary.main,
    },
  },
  langItem: {
    marginLeft: 10,
    marginRight: 0,
    color: '#cccccc',
  },
  langItemSelected: {
    marginLeft: 10,
    marginRight: 0,
    color: '#666666',
  },
  accountMenu: {
    marginTop: 7,
  },
}));

const useStylesMobile = makeStyles((theme) => ({
  langArea: {},
  cursor: {
    '&:hover': {
      cursor: 'pointer',
    },
  },
  langItem: {
    marginLeft: 10,
    marginRight: 0,
    color: '#cccccc',
  },
  langItemSelected: {
    marginLeft: 10,
    marginRight: 0,
    color: '#666666',
  },
}));

const LanguageSelector = ({
  locale,
  locales,
  localesTranslations,
  onClick,
  isInList,
  isMobile,
  listItemClassName,
  onLangChange,
}) => {
  const classes = isMobile ? useStylesMobile() : useStylesDesktop();
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const isReduceMode = locales.length < 3;

  const setLang = (newLang) => {
    onLangChange(newLang);
  };

  if (locales.length <= 1) {
    return null;
  }

  return (
    <>
      {isReduceMode && !isInList && (
        <div className={classes.langArea}>
          {locales.map((lang) => {
            const classItem = `langItem${locale === lang ? 'Selected' : ''}`;
            return (
              <span key={lang}>
                <span
                  role="presentation"
                  className={`${classes[classItem]} ${classes.cursor}`}
                  onClick={() => setLang(lang)}>
                  <Typography variant="h3" display="inline">
                    {lang.toUpperCase()}
                  </Typography>
                </span>
              </span>
            );
          })}
        </div>
      )}
      {!isReduceMode && !isInList && (
        <div
          onMouseEnter={({ currentTarget }) => {
            if (!isMobile) {
              setAnchorEl(currentTarget);
              setModalIsOpen(true);
            }
          }}
          onMouseLeave={() => {
            if (!isMobile) {
              setAnchorEl(null);
              setModalIsOpen(false);
            }
          }}>
          <Button
            onClick={(e) => {
              onClick();
              setAnchorEl(e.currentTarget);
              setModalIsOpen(true);
            }}
            startIcon={<Language />}>
            <Typography variant={isMobile ? 'body1' : 'body2'}>
              {locale.toUpperCase()}
            </Typography>
          </Button>

          {!isMobile && (
            <Popper
              open={Boolean(modalIsOpen)}
              anchorEl={anchorEl}
              style={{ zIndex: 1100 }} // over app bar
            >
              <Paper className={classes.accountMenu} elevation={8}>
                <MenuList id="menu-admin">
                  {map(locales, (locale) => {
                    const localeKey = locale.toLocaleLowerCase();
                    return (
                      <MenuItem
                        key={localeKey}
                        onClick={() => {
                          setLang(localeKey);
                          setModalIsOpen(false);
                        }}>
                        {localesTranslations[locale] || locale}
                      </MenuItem>
                    );
                  })}
                </MenuList>
              </Paper>
            </Popper>
          )}
        </div>
      )}

      {!isReduceMode && isInList && (
        <ListItem
          className={listItemClassName}
          button
          onClick={() => {
            onClick();
            setModalIsOpen(true);
          }}>
          <ListItemIcon style={{ minWidth: 34 }}>
            <Language style={{ marginBottom: '6px' }} />
          </ListItemIcon>

          <ListItemText
            primary={locale.toUpperCase()}
            primaryTypographyProps={{ variant: 'h6' }}
            onClick={() => {
              onClick();
              setModalIsOpen(true);
            }}
          />
        </ListItem>
      )}

      {isReduceMode && isInList && (
        <ListItem className={listItemClassName}>
          <ListItemIcon style={{ minWidth: 34 }}>
            <Language style={{ marginBottom: '6px' }} />
          </ListItemIcon>
          <div className={classes.langArea}>
            {locales.map((lang) => {
              const classItem = `langItem${locale === lang ? 'Selected' : ''}`;
              return (
                <span key={lang}>
                  <span
                    role="presentation"
                    className={`${classes[classItem]} ${classes.cursor}`}
                    onClick={() => setLang(lang)}>
                    <Typography variant="h4" display="inline">
                      {lang.toUpperCase()}
                    </Typography>
                  </span>
                </span>
              );
            })}
          </div>
        </ListItem>
      )}

      {isMobile && (
        <Modal
          open={modalIsOpen}
          onClose={() => setModalIsOpen(false)}
          hideCloseIcon
          modalProps={{
            disableBackdropClick: false,
          }}
          paperStyle={{ padding: 0 }}
          containerStyle={{ width: 'unset' }}>
          <List style={{ padding: 0 }}>
            {map(locales, (locale) => {
              const localeKey = locale.toLocaleLowerCase();
              return (
                <ListItem
                  key={localeKey}
                  dense
                  button
                  onClick={() => {
                    setLang(localeKey);
                    setModalIsOpen(false);
                  }}>
                  <ListItemIcon>
                    <Radio
                      color="primary"
                      edge="start"
                      checked={locale === localeKey}
                      disableRipple
                    />
                  </ListItemIcon>
                  <ListItemText
                    primary={localesTranslations[locale] || locale}
                  />
                </ListItem>
              );
            })}
          </List>
        </Modal>
      )}
    </>
  );
};

LanguageSelector.propTypes = {
  locale: PropTypes.string,
  locales: PropTypes.arrayOf(PropTypes.string).isRequired,
  localesTranslations: PropTypes.shape({}).isRequired,
  onClick: PropTypes.func,
  isInList: PropTypes.bool,
  isMobile: PropTypes.bool,
  listItemClassName: PropTypes.string,
  onLangChange: PropTypes.func,
};

LanguageSelector.defaultProps = {
  onClick: () => {},
  isInList: false,
  isMobile: false,
  listItemClassName: undefined,
  onLangChange: () => {},
};

export default LanguageSelector;
