import {
  black,
  black8,
  functionalBlueInformation,
  grey01,
  grey02,
  grey03,
  grey6,
  redTotal,
  tableRowGrey,
  white,
} from './colors';

export default {
  MuiListItemIcon: {
    root: {
      minWidth: 30,
    },
  },
  MuiPaper: {
    rounded: {
      borderRadius: 20,
    },
  },
  MuiAlert: {
    root: {
      borderRadius: 20,
    },
  },
  MuiAccordion: {
    root: {
      // no spacing between expanded accordions
      '&$expanded': {
        borderTop: '1px solid rgba(0, 0, 0, .125)',
        borderBottom: '1px solid rgba(0, 0, 0, .125)',
        margin: '-1px 0px',
      },
    },
  },

  MuiTableCell: {
    root: {
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      height: 53,
      paddingTop: 0,
      paddingBottom: 0,
    },
    head: {
      color: black,
    },
    body: {
      color: tableRowGrey,
    },
  },
  MuiAvatar: {
    colorDefault: {
      '&[color = "primary"]': {
        backgroundColor: redTotal,
      },
    },
  },
  MuiLinearProgress: {
    barColorSecondary: {
      backgroundColor: grey02,
    },
    colorSecondary: {
      backgroundColor: grey6,
    },
  },
  MuiBadge: {
    colorSecondary: {
      backgroundColor: grey02,
      color: white,
    },
  },
  MuiCard: {
    root: {
      borderRadius: 4,
    },
  },
  MuiMenu: {
    paper: {
      borderRadius: 4,
    },
  },
  MuiAutocomplete: {
    paper: {
      borderRadius: 4,
    },
    noOptions: {
      color: grey02,
    },
    loading: {
      color: grey02,
    },
  },
  MuiChip: {
    root: {
      color: black,
      backgroundColor: grey03,
    },
    outlined: {
      color: black,
      borderColor: grey03,
    },
    outlinedSecondary: {
      color: black,
      borderColor: '#A5ACAD',
      '&:focus': {
        backgroundColor: `${white} !important`,
      },
    },
    colorSecondary: {
      color: white,
      backgroundColor: black,
      '&:focus': {
        backgroundColor: `${black} !important`,
      },
    },
    clickable: {
      '&:hover': {
        opacity: 1,
        backgroundColor: grey03,
      },
      '&:focus': {
        backgroundColor: `${grey03} !important`,
      },
      '&.MuiChip-outlined:hover': {
        backgroundColor: `${white} !important`,
      },
      '&.MuiChip-outlined:focus': {
        backgroundColor: `${white} !important`,
      },
      '&.MuiChip-outlinedSecondary:hover': {
        backgroundColor: `${white} !important`,
      },
      '&.MuiChip-outlinedSecondary:focus': {
        backgroundColor: `${white} !important`,
      },
      '&.MuiChip-outlinedPrimary:hover': {
        backgroundColor: `${white} !important`,
      },
      '&.MuiChip-outlinedPrimary:focus': {
        backgroundColor: `${white} !important`,
      },
    },
    clickableColorPrimary: {
      '&:focus': {
        backgroundColor: `${redTotal} !important`,
      },
      '&:hover': {
        backgroundColor: `${redTotal} !important`,
      },
    },
    clickableColorSecondary: {
      '&:focus': {
        backgroundColor: `${black} !important`,
      },
      '&:hover': {
        backgroundColor: `${black} !important`,
      },
    },
    deleteIcon: {
      color: black,
      opacity: 0.7,
      '&:hover': {
        opacity: 1,
        color: black,
      },
      '.MuiChip-outlined &': { color: grey03 },
    },
    deleteIconColorPrimary: {
      color: white,
      opacity: 0.7,
      '&:hover': {
        opacity: 1,
        color: white,
      },
    },
    deleteIconColorSecondary: {
      color: white,
      opacity: 0.7,
      '&:hover': {
        opacity: 1,
        color: white,
      },
    },
    deleteIconOutlinedColorPrimary: {
      color: `${redTotal} !important`,
      '&:hover': {
        color: `${redTotal} !important`,
      },
    },
    deleteIconOutlinedColorSecondary: {
      color: `${black} !important`,
      opacity: 0.7,
      '&:hover': {
        opacity: 1,
        color: `${black} !important`,
      },
    },
  },
  MuiCircularProgress: {
    colorSecondary: {
      color: grey02,
    },
  },
  MuiSlider: {
    markLabel: {
      color: grey03,
    },
  },
  MuiSwitch: {
    disabled: {},
    colorSecondary: {
      '&.Mui-checked': {
        color: functionalBlueInformation,
      },
      '&.Mui-checked.Mui-disabled': {
        color: grey03,
      },
    },
    track: {
      backgroundColor: grey01,
      '.Mui-disabled + &': {
        backgroundColor: `${grey02} !important`,
      },
      '.MuiSwitch-colorSecondary.Mui-checked + &': {
        backgroundColor: functionalBlueInformation,
      },
    },
  },
  MuiIconButton: {},
  MuiFab: {
    root: {
      '&:disabled': {
        color: grey02, // Grey of disabled button
      },
      color: grey02, // Grey of default button
    },
    extended: {
      height: '50px', // Height of large/default button
      '&:disabled': {
        color: grey02, // Grey of disabled button
      },
      '&$sizeSmall': {
        height: '30px', // Height of small button
      },
      '&$sizeMedium': {
        height: '40px', // Height of medium button
      },
    },
  },
  MuiStepLabel: {
    label: {
      color: grey03,
    },
  },
  MuiTab: {
    root: {
      fontWeight: 'normal',
    },
    textColorPrimary: {
      color: black,
      '&$selected': {
        color: redTotal,
      },
    },
  },
  MuiRadio: {
    root: {
      color: '#A5ACAD',
      '&$checked': {
        color: functionalBlueInformation,
      },
      '&$disabled': {
        color: grey03,
      },
    },
    colorPrimary: {
      color: '#A5ACAD',
      '&$checked': {
        color: redTotal,
      },
    },
    colorSecondary: {
      color: '#A5ACAD',
      '&$checked': {
        color: grey02,
      },
    },
  },
  MuiCheckbox: {
    root: {
      color: '#A5ACAD',
      '&$checked': {
        color: functionalBlueInformation,
      },
      '&$disabled': {
        color: grey03,
      },
    },
    colorPrimary: {
      color: '#A5ACAD',
      '&$checked': {
        color: redTotal,
      },
    },
    colorSecondary: {
      color: '#A5ACAD',
      '&$checked': {
        color: grey02,
      },
    },
  },
  MuiTextField: {
    root: {
      // '& .MuiInput-underline:before': {
      //   borderBottomColor: functionalBlueInformation, // Semi-transparent underline
      // },
      // '& .MuiInput-underline:hover:before': {
      //   borderBottomColor: functionalBlueInformation, // Solid underline on hover
      // },
      '& .MuiInput-underline:after': {
        borderBottomColor: functionalBlueInformation, // Solid underline on focus
      },
      '& .MuiInput-underline.Mui-error:after': {
        borderBottomColor: redTotal, // Solid underline on error
      },
    },
  },
  MuiInputBase: {
    input: {
      color: black,
      '&$disabled': {
        color: grey03,
        '-webkit-text-fill-color': grey03, // fix bug in safari
      },
    },
  },
  MuiOutlinedInput: {
    root: {
      '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
        borderColor: functionalBlueInformation,
      },
    },
  },
  MuiFormLabel: {
    root: {
      color: 'unset',
      '&.Mui-focused': {
        color: functionalBlueInformation,
      },
    },
  },
  MuiButton: {
    text: {
      '&:hover': { backgroundColor: black8 },
      borderRadius: '20px',
      paddingLeft: '18px',
      paddingRight: '18px',
    },
    disabled: {},
    contained: {
      color: grey02, // Grey of default button
      '&:disabled': { backgroundColor: grey03, color: grey02 },
      boxShadow: 'unset',
    },
    sizeSmall: {
      minHeight: '30px', // Height of small button
      borderRadius: '15px',
      paddingLeft: '14px',
      paddingRight: '14px',
    },
    sizeLarge: {
      minHeight: '42px', // Height of large button
      borderRadius: '25px',
      paddingLeft: '20px',
      paddingRight: '20px',
    },
    root: {
      minHeight: '36px', // Height of medium button
      borderRadius: '20px',
      paddingLeft: '18px',
      paddingRight: '18px',
      // boxShadow: 'unset',
    },
  },
  MuiAppBar: {
    colorPrimary: {
      backgroundColor: 'white',
    },
  },
  MuiMenuItem: {
    root: {
      minHeight: 'auto',
    },
  },
  MuiPickersDay: {
    dayDisabled: {
      color: '#a8a8a8',
    },
  },
};
