import moment from 'moment';
import 'moment/locale/en-gb';
import 'moment/locale/fr';
import 'moment/locale/de';
import 'moment/locale/ru';
import 'moment/locale/zh-cn';
import 'moment/locale/pt-br';
import 'moment/locale/nl';
import 'moment/locale/es';
import 'moment/locale/it';
import 'moment/locale/tr';
import 'moment/locale/cs';
import 'moment/locale/hu';
export const loadMomentLocale = locale => {
  const momentLocalesMapping = {
    en: 'en-gb',
    fr: 'fr',
    de: 'de',
    ru: 'ru',
    zh: 'zh-cn',
    pt: 'pt-br',
    nl: 'nl',
    es: 'es',
    it: 'it',
    tr: 'tr',
    cs: 'cs-cz',
    hu: 'hu'
  };
  const momentLocale = momentLocalesMapping[locale];

  if (!momentLocale) {
    console.error(`Error ! Missing moment locale for ${locale}`);
  }

  moment.locale(momentLocale);
};