import { createTheme } from '@material-ui/core/styles';

const defaultTheme = createTheme();

const htmlFontSize = 16;
const pxToRem = (size) => `${size / htmlFontSize}rem`;

export default {
  fontFamily: '"Roboto", "sans-serif"',
  h1: {
    fontFamily: '"nunito", "sans-serif"',
    fontWeight: 'bold',
    fontSize: pxToRem(40),
    lineHeight: pxToRem(60),
  },
  h2: {
    fontFamily: '"nunito", "sans-serif"',
    fontWeight: 'bold',
    fontSize: pxToRem(30),
    lineHeight: pxToRem(40),
  },
  h3: {
    fontFamily: '"nunito", "sans-serif"',
    fontSize: pxToRem(24),
    lineHeight: pxToRem(30),
  },
  h4: {
    fontFamily: '"nunito", "sans-serif"',
    fontWeight: 'bold',
    fontSize: pxToRem(20),
    lineHeight: pxToRem(24),
  },
  h5: {
    fontSize: pxToRem(20),
    lineHeight: pxToRem(24),
  },
  h6: {
    // copy of h4. Carrefull, this one is used by DialogTitle, see dialogs
    fontWeight: 'bold',
    fontSize: pxToRem(18),
    lineHeight: pxToRem(30),
  },
  subtitle1: {
    fontWeight: 'bold',
    fontSize: pxToRem(16),
    lineHeight: pxToRem(24),
  },
  subtitle2: {
    fontWeight: 'bold',
    fontSize: pxToRem(14),
    lineHeight: pxToRem(20),
  },
  body1: {
    // fontWeight: 'bold',
    fontSize: pxToRem(16),
    lineHeight: pxToRem(24),
  },
  body2: {
    // fontWeight: 'bold',
    fontSize: pxToRem(14),
    lineHeight: pxToRem(20),
  },
  button: {
    textTransform: 'none',
    fontWeight: 'bold',
    fontSize: pxToRem(15),
    lineHeight: pxToRem(20),
    [defaultTheme.breakpoints.down('xs')]: {
      fontSize: pxToRem(13),
      lineHeight: pxToRem(18),
    },
  },
  overline: {
    textTransform: 'none',
    fontSize: pxToRem(13),
    lineHeight: pxToRem(16),
  },
  caption: {
    // fontWeight: 'bold',
    fontSize: pxToRem(12),
    lineHeight: pxToRem(18),
  },
};
