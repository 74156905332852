import React, { useState } from 'react';
import Divider from '@material-ui/core/Divider';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Drawer from '@material-ui/core/Drawer';
import clsx from 'clsx';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Button from '@material-ui/core/Button';
import { AccountCircle, Menu as MenuIcon, Close } from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';
import { useRouter } from 'next/router';
import { isNil, map, compact } from 'lodash';
import LanguageSelectorComponent from './LanguageSelectorComponent';
import { headerDefaultProps, headerPropTypes } from './HeaderTypes';

const headerHeight = 90;
const useStyles = makeStyles((theme) => ({
  root: { zIndex: theme.zIndex.drawer },
  drawer: { zIndex: `${theme.zIndex.drawer - 1} !important` },
  toolbarRoot: {
    minHeight: headerHeight,
    height: headerHeight,
    paddingLeft: 15,
    paddingRight: 15,
  },
  image: {
    height: 20,
  },
  grow: {
    flexGrow: 1,
  },
  listItem: {
    height: 40,
    paddingLeft: 20,
    paddingTop: 27,
  },
  nestedList: {
    height: 25,
    paddingLeft: 73,
    paddingTop: 10,
  },
  accountNameListItem: {
    marginTop: -8,
    marginBottom: -8,
    paddingLeft: 55,
  },
  accountName: {
    fontSize: 11,
  },
  languageListItem: {
    marginBottom: 10,
  },
  listRoot: {
    marginTop: headerHeight,
  },
  iconButton: {
    marginRight: -12,
  },
}));

const Header = ({
  tabs,
  accountName,
  locale,
  locales,
  localesTranslations,
  logoPath,
  logoComponent: LogoComponent,
  extraComponents,
  accountMenuLabel,
  isLoginPage,
  logoutLabel,
  onLogout,
  onLangChange,
}) => {
  const classes = useStyles();
  const router = useRouter();

  const [drawerIsOpen, setDrawerIsOpen] = useState(false);

  const closeMenu = () => {
    setDrawerIsOpen(false);
  };

  const navigate = ({ href, as }) => {
    if (router) {
      router.push(href, as, { shallow: true });
    }
  };

  return (
    <AppBar
      data-testid="header"
      position="sticky"
      className={classes.root}
      elevation={0}
    >
      <Drawer
        anchor="top"
        open={drawerIsOpen}
        classes={{
          paper: classes.drawerPaper,
        }}
        className={classes.drawer}
        onClose={closeMenu}
      >
        <List component="nav" className={classes.listRoot}>
          {map(compact(tabs), ({ iconClass, href, label, value, subtabs }) => {
            const IconClass = iconClass;
            return (
              <>
                <ListItem
                  className={classes.listItem}
                  button
                  onClick={() => {
                    closeMenu();
                    if (href) {
                      navigate({ href, tab: value });
                    }
                  }}
                >
                  <ListItemIcon style={{ minWidth: 34 }}>
                    {iconClass && <IconClass style={{ marginBottom: '6px' }} />}
                  </ListItemIcon>
                  {label && (
                    <ListItemText
                      primary={label}
                      primaryTypographyProps={{ variant: 'h6' }}
                    />
                  )}
                </ListItem>
                {map(
                  compact(subtabs),
                  ({ href: subHref, label: subLabel, func }) => (
                    <ListItem
                      button
                      className={clsx(classes.listItem, classes.nestedList)}
                      onClick={() => {
                        closeMenu();
                        if (func) {
                          func();
                        }
                        if (subHref) {
                          navigate({ href: subHref, tab: value });
                        }
                      }}
                    >
                      <ListItemText
                        primary={subLabel}
                        primaryTypographyProps={{ variant: 'subtitle2' }}
                      />
                    </ListItem>
                  )
                )}
              </>
            );
          })}
          {extraComponents && extraComponents}
          {!isNil(accountName) && (
            <>
              <ListItem
                className={classes.listItem}
                onClick={() => {
                  closeMenu();
                  navigate({ href: '/user/my-account' });
                }}
              >
                <ListItemIcon style={{ minWidth: 34 }}>
                  <AccountCircle style={{ marginBottom: '6px' }} />
                </ListItemIcon>
                {accountMenuLabel && (
                  <ListItemText
                    primary={accountMenuLabel}
                    primaryTypographyProps={{ variant: 'h6' }}
                  />
                )}
              </ListItem>
              <ListItem
                className={clsx(
                  classes.listItem,
                  classes.nestedList,
                  classes.accountNameListItem
                )}
                onClick={() => {
                  closeMenu();
                  navigate({ href: '/user/my-account' });
                }}
              >
                <ListItemText
                  primary={accountName}
                  primaryTypographyProps={{
                    variant: 'subtitle2',
                    color: 'primary',
                    className: classes.accountName,
                  }}
                />
              </ListItem>
            </>
          )}
          <LanguageSelectorComponent
            locale={locale}
            locales={locales}
            localesTranslations={localesTranslations}
            classes={classes}
            onLangChange={onLangChange}
            isInList
            isMobile
            listItemClassName={clsx(classes.listItem, classes.languageListItem)}
          />
        </List>
        <Button
          variant="contained"
          color="primary"
          style={{ borderRadius: 0 }}
          onClick={onLogout}
        >
          {logoutLabel}
        </Button>
      </Drawer>
      <Toolbar classes={{ root: classes.toolbarRoot }}>
        {logoPath && (
          <img src={logoPath} alt="Logo" className={classes.image} />
        )}
        {LogoComponent}
        <div className={classes.grow} />
        {isLoginPage && (
          <LanguageSelectorComponent
            locale={locale}
            locales={locales}
            localesTranslations={localesTranslations}
            classes={classes}
            onLangChange={onLangChange}
            isMobile
          />
        )}

        {!isLoginPage && (
          <IconButton
            onClick={() => setDrawerIsOpen(!drawerIsOpen)}
            aria-controls="menu-appbar"
            aria-haspopup="true"
            color="primary"
            className={classes.iconButton}
          >
            {drawerIsOpen ? <Close /> : <MenuIcon />}
          </IconButton>
        )}
      </Toolbar>
      <Divider />
    </AppBar>
  );
};

Header.propTypes = headerPropTypes;
Header.defaultProps = headerDefaultProps;

export default Header;
