import {
  redTotal,
  carminRed,
  white,
  black,
  grey03,
  grey04,
  grey10,
  redTotalAlpha,
  functionalRedFailure,
  functionalOrangeWarning,
  functionalGreenSuccess,
  functionalBlueInformation,
} from './colors';

export default {
  error: {
    // light: '#abcdef',
    main: functionalRedFailure,
    // dark: '#abcdef',
    // contrastText: '#abcdef',
  },
  warning: {
    // light: '#abcdef',
    main: functionalOrangeWarning,
    // dark: '#abcdef',
    // contrastText: '#abcdef',
  },
  info: {
    // light: '#abcdef',
    main: functionalBlueInformation,
    // dark: '#abcdef',
    // contrastText: '#abcdef',
  },
  success: {
    // light: '#abcdef',
    main: functionalGreenSuccess,
    // dark: '#abcdef',
    // contrastText: '#abcdef',
  },
  grey: {
    50: white, // switch enabled off
    100: '#abcdef',
    200: '#abcdef',
    300: grey04,
    400: grey03, // switch disabled
    500: '#abcdef',
    600: '#abcdef',
    // '700': '#abcdef', // tool tip background on material-table
    800: '#abcdef',
    900: '#abcdef',
    // A100: '#abcdef', // Fab on hover color
    A200: '#abcdef',
    A400: '#abcdef',
    A700: '#abcdef',
  },
  primary: {
    light: white,
    main: redTotal,
    dark: carminRed,
    contrastText: white,
  },
  secondary: {
    light: grey04,
    main: white,
    dark: redTotalAlpha,
    contrastText: redTotal,
  },
  accent: {
    light: '#abcdef',
    main: '#abcdef',
    dark: '#abcdef',
    contrastText: '#abcdef',
  },
  warn: {
    light: '#abcdef',
    main: '#abcdef',
    dark: '#abcdef',
    contrastText: '#abcdef',
  },

  text: {
    primary: black,
    secondary: redTotal,
    disabled: grey03,
    hint: grey10, // Day color in calendar header
  },

  common: {
    black: grey03, // switch track
    // white: '#abcdef',
  },

  action: {
    active: 'rgba(0, 0, 0, 0.54)', // I put it back for icon button
    // hover: '#abcdef',//'rgba(0, 0, 0, 0.04)',// background of hovered items in lists
    hoverOpacity: 0.04,
    // selected: '#abcdef',//'rgba(0, 0, 0, 0.08)',// background of selected line in material-table pagination
    selectedOpacity: 0.08,
    disabled: grey03, // 'rgba(0, 0, 0, 0.26)',
    disabledBackground: grey03, // 'rgba(0, 0, 0, 0.12)',
    disabledOpacity: 0.38,
    focus: '#abcdef', // 'rgba(0, 0, 0, 0.12)',
    focusOpacity: 0.12,
    activatedOpacity: 0.12,
  },
};
