import bcrypt from 'bcryptjs';
import { isEmpty, size } from 'lodash';
export const hashPassword = password => {
  if (!password) {
    throw new Error('Please provide a password');
  }

  const salt = bcrypt.genSaltSync();
  return bcrypt.hashSync(password, salt);
};
export const verifyPassword = async (password, hash) => {
  if (!password || !hash) {
    return false;
  }

  return bcrypt.compare(password, hash);
};
export const isValidPassword = password => {
  if (password === 'test') return true; // TODO : remove !!!

  if (isEmpty(password)) return false;
  if (size(password) < 8) return false; // size 8

  if (!password.match(/[a-z]/g)) return false; // 1 lower case

  if (!password.match(/[A-Z]/g)) return false; // 1 upper case

  if (!password.match(/[0-9]/g)) return false; // 1 digit

  if (!password.match(/[^0-9a-zA-Z]/g)) return false; // 1 special character

  return true;
};