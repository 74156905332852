import React, { useState, useEffect } from 'react';
import Divider from '@material-ui/core/Divider';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import MenuItem from '@material-ui/core/MenuItem';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import MenuList from '@material-ui/core/MenuList';
import Person from '@material-ui/icons/Person';
import { makeStyles } from '@material-ui/core/styles';
import { useRouter } from 'next/router';
import { isNil, map, compact } from 'lodash';
import LanguageSelectorComponent from './LanguageSelectorComponent';
import typography from '../../theme/typography';
import { redTotal } from '../../theme/colors';
import { headerDefaultProps, headerPropTypes } from './HeaderTypes';
import { Button } from '@material-ui/core';

const LAYOUT_MAX_WIDTH = 1280;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    borderBottom: '1px #e5e5e5 solid',
  },
  title: { flexGrow: 1, paddingBottom: 0, textAlign: 'center' },
  toolbarRoot: {
    height: 118,
    paddingLeft: 25,
    paddingRight: 25,
    paddingTop: 15,
    maxWidth: LAYOUT_MAX_WIDTH,
    width: '100%',
  },
  image: {
    height: 30,
  },
  scrollButtons: {
    color: 'black',
    width: 29,
    [theme.breakpoints.down('sm')]: {
      width: 12,
    },
  },
  tabRoot: {
    ...typography.h3,
    bottom: 0,
    minWidth: 'auto',
    '&:hover': {
      color: redTotal,
    },
  },
  tabsRoot: {
    maxWidth: '100%',
    minWidth: '10px',
    marginBottom: -1, // very important to make tab hover work properly
    alignSelf: 'flex-start',
    paddingLeft: 30,
  },
  indicator: { height: 3, backgroundColor: redTotal },
  grow: {
    flexGrow: 1,
    height: 60,
  },
  account: {
    alignItems: 'inherit',
    justifyContent: 'inherit',
    margin: 'auto',
  },
  accountMenu: {
    marginTop: 7,
  },
  sectionDesktop: {
    display: 'flex',
    height: 60,
    marginLeft: -6,
    marginRight: 20,
  },
}));

const Header = ({
  tab,
  tabs,
  accountName,
  accountMenuItems,
  isLoginPage,
  locale,
  locales,
  localesTranslations,
  logoPath,
  logoComponent: LogoComponent,
  onLangChange,
  TopBarRenderer,
}) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const [currentMenuTab, setCurrentMenuTab] = useState(null);
  const [tabAnchorEl, setTabAnchorEl] = useState(null);
  const [currentTab, setCurrentTab] = useState(tab);
  const router = useRouter();

  const closeMenu = () => {
    setTabAnchorEl(null);
    setAnchorEl(null);
    setCurrentMenuTab(null);
  };

  const navigate = ({ href, as, tab: newTab }) => {
    if (newTab) {
      setCurrentTab(newTab);
    }
    setCurrentMenuTab(false);
    if (router) {
      router.push(href, as, { shallow: true });
    }
  };

  useEffect(() => {
    setCurrentTab(tab || false);
    closeMenu();
  }, [tab]);

  return (
    <AppBar
      data-testid="header.appBar"
      position="sticky"
      className={classes.root}
      elevation={0}
      onMouseLeave={closeMenu}>
      <Toolbar classes={{ root: classes.toolbarRoot }}>
        {logoPath && (
          <img src={logoPath} alt="Logo" className={classes.image} />
        )}
        {LogoComponent}
        <div
          style={{
            flexGrow: 1,
            display: 'flex',
            flexDirection: 'column',
            height: '100%',
            maxWidth: '100%',
            minWidth: '10px',
          }}>
          <div
            style={{
              display: 'flex',
              justifyContent: 'flex-end',
              alignItems: 'center',
            }}>
            <TopBarRenderer>
              {!isNil(accountName) && !isLoginPage && (
                <>
                  <div>
                    <Button
                      style={{ fontWeight: 'normal' }}
                      onMouseEnter={(e) => setAnchorEl(e.currentTarget)}
                      data-testid="layout.accountButton"
                      aria-controls="menu-appbar"
                      aria-haspopup="true"
                      startIcon={<Person />}>
                      {accountName}
                      <Popper
                        open={Boolean(anchorEl)}
                        anchorEl={anchorEl}
                        style={{ zIndex: 1100 }} // over app bar
                      >
                        <Paper className={classes.accountMenu} elevation={8}>
                          <MenuList id="menu-appbar">
                            {map(
                              compact(accountMenuItems),
                              ({ label, href, func, value }) => (
                                <MenuItem
                                  data-testid={`layout.account.${value}`}
                                  key={href || label}
                                  onClick={() => {
                                    closeMenu();
                                    if (func) {
                                      func();
                                    }
                                    if (href) {
                                      navigate({ href });
                                    }
                                  }}>
                                  {label}
                                </MenuItem>
                              )
                            )}
                          </MenuList>
                        </Paper>
                      </Popper>
                    </Button>
                  </div>
                </>
              )}
              <div onMouseEnter={closeMenu}>
                <LanguageSelectorComponent
                  locale={locale}
                  locales={locales}
                  localesTranslations={localesTranslations}
                  onLangChange={onLangChange}
                />
              </div>
            </TopBarRenderer>
          </div>
          <div style={{ flexGrow: 1 }} />
          {!isLoginPage && !isNil(accountName) ? (
            <Tabs
              data-testid="header.tabs"
              onMouseLeave={closeMenu}
              textColor="primary"
              indicatorColor="primary"
              variant="scrollable"
              scrollButtons="on"
              aria-label="menu-tabs"
              value={currentMenuTab || currentTab}
              onChange={(ev, value) => setCurrentMenuTab(value)}
              classes={{
                scrollButtons: classes.scrollButtons,
                indicator: classes.indicator,
                root: classes.tabsRoot,
              }}>
              {map(
                compact(tabs),
                ({ iconClass, href, label, value, subtabs }) => {
                  const IconClass = iconClass;
                  return (
                    <Tab
                      data-testid={`header.tabs.${value}`}
                      classes={{ root: classes.tabRoot }}
                      onClick={
                        href ? () => navigate({ href, tab: value }) : undefined
                      }
                      onMouseEnter={({ currentTarget }) => {
                        if (subtabs) {
                          setTabAnchorEl(currentTarget);
                        } else {
                          closeMenu();
                        }
                        setCurrentMenuTab(value);
                      }}
                      style={{ minWidth: '10px' }}
                      value={value}
                      icon={
                        !label &&
                        iconClass && (
                          <IconClass style={{ marginBottom: '6px' }} />
                        )
                      }
                      label={
                        <Typography variant="subtitle2">{label}</Typography>
                      }
                      key={value}
                    />
                  );
                }
              )}
              {map(compact(tabs), ({ subtabs, value }) => {
                if (!subtabs) return undefined;
                return (
                  <Popper
                    open={Boolean(tabAnchorEl) && currentMenuTab === value}
                    anchorEl={tabAnchorEl}
                    getContentAnchorEl={null}
                    style={{ zIndex: 1100 }} // over app bar
                    key={value}>
                    <Paper elevation={8}>
                      <MenuList id="menu-admin" key={value}>
                        {map(
                          compact(subtabs),
                          ({ href, label, func, value: subvalue }) => (
                            <MenuItem
                              data-testid={`header.tabs.${value}.${subvalue}`}
                              key={href || label}
                              onClick={() => {
                                closeMenu();
                                if (func) {
                                  func();
                                }
                                if (href) {
                                  navigate({ href, tab: value });
                                }
                              }}>
                              {label}
                            </MenuItem>
                          )
                        )}
                      </MenuList>
                    </Paper>
                  </Popper>
                );
              })}
            </Tabs>
          ) : (
            <div className={classes.grow} />
          )}
        </div>
      </Toolbar>
      <Divider />
    </AppBar>
  );
};

Header.propTypes = headerPropTypes;
Header.defaultProps = headerDefaultProps;

export default Header;
