/* eslint-disable no-console */
import { onError } from '@apollo/client/link/error';
import { signOut } from 'next-auth/react';
import Router from 'next/router';
const errorsLink = onError(_ref => {
  var _graphQLErrors$0$exte, _graphQLErrors$, _graphQLErrors$$exten, _graphQLErrors$$exten2;

  let {
    graphQLErrors,
    networkError
  } = _ref;

  if (graphQLErrors && true) {
    console.error(graphQLErrors);
  }

  if (graphQLErrors && ((_graphQLErrors$0$exte = graphQLErrors[0].extensions) === null || _graphQLErrors$0$exte === void 0 ? void 0 : _graphQLErrors$0$exte.code) === 'UNAUTHENTICATED' && true) {
    console.log('UNAUTHENTICATED, return to auth');
    signOut();
  }

  if ((graphQLErrors === null || graphQLErrors === void 0 ? void 0 : (_graphQLErrors$ = graphQLErrors[0]) === null || _graphQLErrors$ === void 0 ? void 0 : (_graphQLErrors$$exten = _graphQLErrors$.extensions) === null || _graphQLErrors$$exten === void 0 ? void 0 : (_graphQLErrors$$exten2 = _graphQLErrors$$exten.response) === null || _graphQLErrors$$exten2 === void 0 ? void 0 : _graphQLErrors$$exten2.status) === 404 && true) {
    Router.push('/404', '/404', {
      shallow: true
    });
  }

  if (networkError) console.error(networkError);
});
export default errorsLink;