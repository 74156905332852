import { Container } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Header from '../Header';
import Footer from '../Footer';
import { lightBlueTotal } from '../../theme/colors';

const LAYOUT_MAX_WIDTH = 1280;

const footerHeight = ({ isMobile }) => (isMobile ? 'auto' : 60);

const useStyles = ({ isMobile }) =>
  makeStyles((theme) => ({
    push: {
      height: footerHeight({ isMobile }),
    },
    footer: {
      height: footerHeight({ isMobile }),
      position: 'static',
    },
    gridContainer: {
      paddingTop: isMobile ? 15 : 20,
      paddingBottom: isMobile ? 15 : 20,
      paddingLeft: isMobile ? 15 : 25,
      paddingRight: isMobile ? 15 : 25,
      maxWidth: LAYOUT_MAX_WIDTH,
    },
    topBar: { backgroundColor: lightBlueTotal },
  }))();

const Layout = ({
  children,
  footerProps,
  headerProps,
  isMobile,
  hideHeader = false,
  showBanner = false,
  BannerRenderer,
}) => {
  const classes = useStyles({ isMobile });

  return (
    <>
      <div
        style={{
          minHeight: `calc(100vh - ${footerHeight({ isMobile })}px)`,
        }}
      >
        {showBanner && (
          <AppBar position="static" className={classes.topBar}>
            <BannerRenderer></BannerRenderer>
          </AppBar>
        )}
        {!hideHeader && <Header {...headerProps} isMobile={isMobile} />}
        <Container disableGutters className={classes.gridContainer}>
          {children}
        </Container>
        <div className={classes.push} />
      </div>
      <footer className={classes.footer}>
        <Footer
          {...footerProps}
          height={footerHeight({ isMobile })}
          isMobile={isMobile}
        />
      </footer>
    </>
  );
};
Layout.propTypes = {
  headerProps: PropTypes.shape(Header.propTypes),
  footerProps: PropTypes.shape(Footer.propTypes).isRequired,
  isMobile: PropTypes.bool,
  hideHeader: PropTypes.bool,
  showBanner: PropTypes.bool,
  BannerRenderer: PropTypes.any,
};
Layout.defaultProps = {
  isMobile: false,
};
export default Layout;
